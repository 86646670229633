import { Theme } from '@nivo/core';
import { Timeframe } from './timeframe/Timeframe';

export const getDateFormat = (timeframe: Timeframe) => {
  switch (timeframe.key) {
    case 'D':
      return 'HH:mm';
    case 'W':
      return 'dd MMM';
    case 'M':
      return 'dd MMM';
    case 'Y':
      return 'MMM yy';
    default:
      return 'MMM d';
  }
};

export const theme: Theme = {
  textColor: 'var(--text-secondary)',
  fontSize: 12,
  axis: {
    ticks: {
      line: {
        stroke: 'transparent',
      },
    },
    domain: {
      line: {
        color: 'transparent',
        strokeWidth: 0,
      },
    },
  },
  grid: {
    line: {
      stroke: 'var(--icon-secondary)',
    },
  },
  crosshair: {
    line: {
      stroke: 'var(--icon-secondary)',
    },
  },
};
