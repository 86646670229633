import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { uniq } from 'lodash-es';
import { WalletAccount } from '../../services';

export enum TokenFilter {
  MangataDefault = 'mangataDefault',
  UserOwned = 'userOwned',
  LiquidityPool = 'liquidityPool',
  Other = 'other',
}

export enum Theme {
  Day = 'day',
  Night = 'night',
}

export interface UserStore {
  selectedWallet: string | null;
  selectedAccount: WalletAccount | null;
  isWalletUnlocked: boolean;
  tokenFilters: TokenFilter[];
  theme: Theme;
  isSystemThemeActive: boolean;
  setTheme: (theme: Theme, isSystemThemeActive?: boolean) => void;
  setSystemThemeActive: (isSystemThemeActive: boolean) => void;
  setSelectedWallet: (selectedWallet: string | null) => void;
  setWalletUnlocked: (isWalletUnlocked: boolean) => void;
  setSelectedAccount: (selectedAccount: WalletAccount | null) => void;
  disconnectWallet: () => void;
  setTokensFilter: (key: TokenFilter | undefined, toAdd: boolean) => void;
}

const USER_STORAGE_KEY = 'user';
const DEFAULT_TOKEN_FILTERS = [TokenFilter.MangataDefault, TokenFilter.UserOwned];

export const useUserStore = create(
  persist<UserStore>(
    (set, get) => ({
      theme: Theme.Day,
      selectedWallet: null,
      selectedAccount: null,
      tokenFilters: DEFAULT_TOKEN_FILTERS,
      isWalletUnlocked: true,
      isSystemThemeActive: true,
      setSystemThemeActive: (isSystemThemeActive: boolean) => set({ isSystemThemeActive }),
      setTheme: (theme: Theme, isSystemThemeActive?: boolean) =>
        set(isSystemThemeActive !== undefined ? { theme, isSystemThemeActive } : { theme }),
      setWalletUnlocked: (isWalletUnlocked: boolean) => set({ isWalletUnlocked }),
      setSelectedWallet: (selectedWallet: string | null) =>
        set({ selectedWallet, selectedAccount: null }),
      setSelectedAccount: (selectedAccount: WalletAccount | null) => set({ selectedAccount }),
      disconnectWallet: () => set({ selectedWallet: null, selectedAccount: null }),
      setTokensFilter: (key: TokenFilter | undefined, toAdd: boolean) =>
        set(setTokensFilter(get(), key, toAdd)),
    }),
    {
      name: USER_STORAGE_KEY,
      storage: createJSONStorage(() => localStorage),
      version: 3,
    },
  ),
);

export const setTokensFilter = (
  state: UserStore | undefined,
  key: TokenFilter | undefined,
  toAdd: boolean,
) => {
  if (!state) {
    return { tokenFilters: DEFAULT_TOKEN_FILTERS };
  }

  const { tokenFilters } = state;

  if (!key) {
    return {
      tokenFilters: toAdd ? Object.values(TokenFilter) : [TokenFilter.MangataDefault],
    };
  }

  return {
    tokenFilters: toAdd
      ? uniq([...tokenFilters, key])
      : tokenFilters.filter((filter) => filter !== key),
  };
};
