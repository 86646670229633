import cls from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevron-down.svg';
import { ClassName, TestId } from '../../../types';
import { Container } from '../../Container/Container';
import { Text } from '../../Text/Text';
import { Button } from '../../Button/Button';

export interface ChartDropdownProps extends ClassName, TestId {
  onSelect: (id: number) => void;
  data: string[];
  isDisabled?: boolean;
  activeIndex?: number;
  titleId?: string;
}

export function ChartDropdown({
  onSelect,
  className,
  'data-testid': testId,
  data,
  activeIndex,
  titleId,
}: ChartDropdownProps) {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const handleSelect = (id: number) => () => {
    onSelect(id);
    handleToggle();
  };

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node) && isOpen) {
        setOpen(false);
      }
    };

    const handleEscPress = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === 'escape') {
        setOpen(false);
      }
    };

    document.addEventListener('keyup', handleEscPress);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keyup', handleEscPress);
    };
  }, [ref, isOpen, setOpen]);

  return (
    <div className={cls('relative', className)} ref={ref}>
      <Button
        size="s"
        variant="secondary"
        TrailIcon={ChevronDown}
        onClick={handleToggle}
        className="bg-default"
      >
        {data[activeIndex || 0]}
      </Button>
      <div
        data-testid={`${testId}-list`}
        className={cls(
          'mt-3 pb-1 absolute left-2 bg-default border-1 z-dropdown border-default rounded-lg w-[256px]',
          isOpen ? '' : 'hidden',
          titleId ? 'pt-5' : 'pt-0',
        )}
      >
        {titleId && (
          <Text type="body-tiny" color="secondary" className="m-5" id={titleId} uppercase />
        )}
        <ul className="py-2">
          {data.map((label, i) => (
            <li key={i}>
              <Container
                onClick={handleSelect(i)}
                alignItems="center"
                fullWidth
                justifyContent="space-between"
                className={cls(
                  'block px-5 py-[10px] cursor-pointer group',
                  activeIndex === i && 'active',
                )}
                data-testid={`${testId}-item`}
              >
                <Text type="body-m">{label}</Text>
                <div
                  className={cls(
                    'w-1.5 h-1.5 bg-highlight mr-1 rounded-full group-hover:visible',
                    activeIndex !== i && 'invisible',
                  )}
                />
              </Container>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
