import Decimal from 'decimal.js';
import { Asset, EnvConfig, WalletAccount } from 'core';
import { ChainSelectItem } from 'ui';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isNil } from 'lodash-es';

export enum WithdrawalUIState {
  Default,
  SelectToken,
  Withdrawing,
  Success,
}

export interface WithdrawalUIStore {
  chain: ChainSelectItem | null;
  destinationAddress: string | null;
  destinationAccount: WalletAccount | null;
  amount: string | null;
  computed: {
    buttonLabelId: string;
    hasAmount: boolean;
  };
  asset: Asset | null;
  uiState: WithdrawalUIState;
  setChain: (chain: ChainSelectItem | null, withAccountReset?: boolean) => void;
  setDestinationAddress: (destinationAddress: string | null) => void;
  setDestinationAccount: (destinationAccount: WalletAccount | null) => void;
  setAsset: (asset: Asset | null) => void;
  setUIState: (state: WithdrawalUIState) => void;
  setAmount: (amount: string | null) => void;
  dispose: () => void;
}

export const useWithdrawalStore = create(
  devtools<WithdrawalUIStore>((set, get) => ({
    chain: null,
    destinationAddress: null,
    destinationAccount: null,
    amount: null,
    asset: null,
    uiState: WithdrawalUIState.Default,
    computed: {
      get buttonLabelId() {
        return computeButtonLabel(get());
      },
      get hasAmount() {
        return computeHasAmount(get());
      },
    },
    setAmount: (amount) => {
      if (isAmountValid(amount)) {
        set({ amount });
      }
    },
    setUIState: (uiState) => set({ uiState }),
    setChain: (chain, withAccountReset = true) => {
      set(
        withAccountReset
          ? { chain, asset: null, amount: null, destinationAddress: null }
          : { chain, asset: null, amount: null },
      );
    },
    setDestinationAddress: (destinationAddress) => set({ destinationAddress }),
    setDestinationAccount: (destinationAccount) => set({ destinationAccount }),
    setAsset: (asset) => set({ asset }),
    dispose: () =>
      set({
        chain: null,
        destinationAddress: null,
        amount: null,
        asset: null,
        uiState: WithdrawalUIState.Default,
      }),
  })),
);

function isAmountValid(amount: string | null | undefined) {
  if (amount === '') {
    return true;
  }

  if (isNil(amount)) {
    return false;
  }

  try {
    return !new Decimal(amount).isNaN();
  } catch {
    return false;
  }
}

function computeHasAmount(state: WithdrawalUIStore) {
  if (!state || !state.asset || !state.amount) return false;

  try {
    const hasAmount = new Decimal(state.amount).gt(new Decimal(0));
    return hasAmount;
  } catch {
    return false;
  }
}

function computeButtonLabel(state: WithdrawalUIStore): string {
  switch (true) {
    case state === undefined:
      return 'bridge.button.withdraw';
    case state.chain === null:
      return 'bridge.button.selectChain';
    case state.asset === null:
      return 'bridge.button.selectToken';
    case state.amount === null:
      return 'bridge.button.amount';
    case EnvConfig.isRollupEnv && state.destinationAddress === null:
      return 'bridge.button.selectAccount';
    default:
      return 'bridge.button.withdraw';
  }
}
