import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface StakingUIStore {
  isAddModalOpen: boolean;
  isRemoveModalOpen: boolean;
  isSearchOpen: boolean;
  collatorAddress: string | null;
  setIsSearchOpen: (isOpen: boolean) => void;
  setAddModalOpen: (isOpen: boolean, collatorAddress?: string) => void;
  setRemoveModalOpen: (isOpen: boolean, collatorAddress?: string) => void;
}

export const useStakingUIStore = create(
  devtools<StakingUIStore>((set) => ({
    isSearchOpen: false,
    isAddModalOpen: false,
    isRemoveModalOpen: false,
    collatorAddress: null,
    setIsSearchOpen: (isSearchOpen) => set({ isSearchOpen }),
    setAddModalOpen: (isAddModalOpen, collatorAddress) =>
      set({ isAddModalOpen, collatorAddress: collatorAddress || null, isRemoveModalOpen: false }),
    setRemoveModalOpen: (isRemoveModalOpen, collatorAddress) =>
      set({ isRemoveModalOpen, collatorAddress: collatorAddress || null, isAddModalOpen: false }),
  })),
);
