import { Button, Container, shortenAddress } from 'ui';
import { ReactComponent as GaspLogo } from 'icons/gasp-logo.svg';
import { useAccount, useDisconnect } from 'wagmi';

interface HeaderProps {
  onConnect: () => void;
  onDisconnect: () => void;
}

export const Header = ({ onConnect, onDisconnect }: HeaderProps) => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const handleDisconnect = () => {
    disconnect();
    onDisconnect();
  };

  return (
    <Container className="z-10 w-full items-center justify-between max-w-[1200px] px-4">
      <Container alignItems="center" className="mt-6">
        <GaspLogo className="h-auto w-[70px]" />
      </Container>
      <Button
        variant="base"
        onClick={!address ? onConnect : handleDisconnect}
        className="bg-default text-primary font-body-m items-center py-2 px-3 rounded-lg h-auto"
      >
        {address ? `Connected:${shortenAddress(address)}` : 'Connect Metamask'}
        {address && <Container className="text-alert ml-2">Disconnect</Container>}
      </Button>
    </Container>
  );
};
