import { useCallback, useEffect } from 'react';
import { useUserStore, Theme } from 'core';

const windowMedia = window.matchMedia('(prefers-color-scheme: dark)');

export const useSystemTheme = () => {
  const { isSystemThemeActive, setTheme, theme } = useUserStore();

  const onSystemChangeTheme = useCallback(
    ({ matches }: MediaQueryListEvent) => {
      if (isSystemThemeActive) {
        setTheme(matches ? Theme.Night : Theme.Day);
      }
    },
    [setTheme, isSystemThemeActive],
  );

  useEffect(() => {
    if (isSystemThemeActive) {
      windowMedia.addEventListener('change', onSystemChangeTheme);
    }

    return () => {
      windowMedia.removeEventListener('change', onSystemChangeTheme);
    };
  }, [isSystemThemeActive, onSystemChangeTheme]);

  useEffect(() => {
    if (isSystemThemeActive) {
      setTheme(windowMedia.matches ? Theme.Night : Theme.Day);
    }
  }, [isSystemThemeActive, setTheme]);

  return {
    isSystemThemeActive,
    theme,
  };
};
