import { Token } from 'gasp-sdk';

export enum AssetType {
  Native,
  LP,
}

export enum AssetOrigin {
  Native = 'native',
  Ethereum = 'ethereum',
  Arbitrum = 'arbitrum',
}

export interface AssetSource {
  address: string;
  chainId: string;
  iconUrl: string | undefined;
}

export interface Asset extends Pick<Token, 'id' | 'name' | 'symbol'> {
  decimals: number;
  type: AssetType;
  origin?: AssetOrigin;
  source?: AssetSource;
  lpAssets?: [Asset, Asset];
}

export interface TokenStatistics {
  tokenId: string;
  tokenName: string;
  symbol: string;
  priceInUSD: string;
  volume24hInUSD: string;
  liquidity24hInUSD: string;
  priceChange24hInPerc: string;
  volumeChange24hInPerc: string;
}

export enum TokenFilterType {
  All = 'all',
  Owned = 'owned',
}
