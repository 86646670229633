import { create } from 'zustand';
import { TxStatus, TxAsset, TxType } from '../Transaction';
import { TxOptions } from '../TxBase';

export interface TransactionError {
  name: string;
  message?: string;
}

export interface TxMetadataTokens {
  tokens: TxAsset[];
  symbol?: never;
  amount?: never;
}

export interface TxMetadataDefault {
  tokens?: never;
  symbol: string;
  amount: string;
}

export type TransactionMetadata = (TxMetadataTokens | TxMetadataDefault) & {
  amountPrefix?: string;
  amountSuffix?: string;
  joinSymbolId?: string;
};

export interface Transaction {
  status: TxStatus;
  type: TxType;
  explorerUrl?: string;
  tokens?: TxAsset[];
  error?: TransactionError;
  id: string;
  isVisible: boolean;
  metadata: TransactionMetadata;
  options: TxOptions;
}

export interface TransactionStore {
  list: Transaction[];
  set(id: string, content: Partial<Transaction>): void;
  remove(id?: string): void;
  hide(id: string): void;
}

type SetFn = (fn: (state: TransactionStore) => Partial<TransactionStore>) => void;

export const useTransactionStore = create<TransactionStore>((set) => ({
  list: [],
  set: setTx(set),
  remove: removeTx(set),
  hide: hideTx(set),
}));

function setTx(set: SetFn) {
  return (id: string, content: Partial<Transaction>) => {
    set((state) => {
      const index = state.list.findIndex((tx) => tx.id === id);
      if (index === -1) {
        state.list.push({ id, ...content } as Transaction);
      } else {
        state.list[index] = { ...state.list[index], ...content };
      }
      return { list: state.list };
    });
  };
}

function hideTx(set: SetFn) {
  return (id: string) => {
    set((state) => {
      const index = state.list.findIndex((tx) => tx.id === id);
      if (index !== -1) {
        state.list[index].isVisible = false;
      }
      return { list: state.list };
    });
  };
}

function removeTx(set: SetFn) {
  return (id?: string) => {
    if (!id) {
      return;
    }
    set((state) => {
      const index = state.list.findIndex((tx) => tx.id === id);
      if (index !== -1) {
        state.list.splice(index, 1);
      }
      return { list: state.list };
    });
  };
}
