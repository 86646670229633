import { ApiPromise } from '@polkadot/api';
import { StorageKey, Option } from '@polkadot/types';
import { u32 } from '@polkadot/types/primitive';
import { MangataTypesAssetsL1Asset } from '@polkadot/types/lookup';

export const getL2ToL1AssetMap =
  (api: ApiPromise | null) =>
  async (): Promise<[StorageKey<[u32]>, Option<MangataTypesAssetsL1Asset>][] | null> => {
    if (!api) {
      return null;
    }

    const res = api.query.assetRegistry.idToL1Asset.entries();

    return res;
  };
