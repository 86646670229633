import 'gasp-types';
import { Mangata } from 'gasp-sdk';
import { useRef } from 'react';
import { EnvConfig } from '../../envConfig';

export const useSDK = () => {
  const mangataRef = useRef(Mangata.instance([EnvConfig.API_URL]));

  return mangataRef.current;
};
