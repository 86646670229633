import { UseMutationResult } from '@tanstack/react-query';
import { Button, Container, Text } from 'ui';
import { GASP_TOKEN } from '../hooks';

interface AddTokenLayoutProps {
  addGaspMutation: UseMutationResult<void, unknown, void, unknown>;
}

export const AddTokenLayout = ({ addGaspMutation }: AddTokenLayoutProps) => {
  return (
    <Container className="z-10 mt-4" column alignItems="center" fullWidth>
      <Text type="body-m" weight="bold">
        {GASP_TOKEN.name} not showing in your Metamask wallet?{' '}
      </Text>
      <Container>
        <Button
          variant="base"
          className="font-body-s font-bold mr-4 underline"
          onClick={() => addGaspMutation.mutate()}
        >
          Add {GASP_TOKEN.name} to Metamask
        </Button>
      </Container>
    </Container>
  );
};
