import { Link } from 'react-router-dom';
import cls from 'classnames';
import { Container, Text } from 'ui';
import { ReactComponent as LowLiquidityIcon } from 'assets/icons/lowLiquidity.svg';
import { ReactComponent as NoLiquidityIcon } from 'assets/icons/noLiquidity.svg';
import { ClassName, RouteDataError, TestId } from 'core';

interface SwapBannerProps extends ClassName, TestId {
  variant: RouteDataError;
}

const VARIANT_STYLES = {
  [RouteDataError.InsufficientLiquidity]: {
    Icon: <LowLiquidityIcon className={cls('w-8 h-8 mr-6')} />,
  },
  [RouteDataError.NoPools]: {
    Icon: <NoLiquidityIcon className={cls('w-8 h-8 mr-6')} />,
  },
  [RouteDataError.InsufficientInputAmount]: null,
};

export const SwapBanner = ({
  variant,
  className,
  'data-testid': testId = 'swapBanner',
}: SwapBannerProps) => {
  if (!VARIANT_STYLES[variant]) {
    return null;
  }

  const AddLiquidityCta = (
    <Link to="/pools">
      <Text id="swap.banner.cta.addLiquidity" className="underline" weight="black" />
    </Link>
  );

  return (
    <Container
      inline
      alignItems="center"
      data-testid={testId}
      className={cls('rounded px-5 py-4 w-full bg-highlight-soft', className)}
    >
      <Container>{VARIANT_STYLES[variant]?.Icon}</Container>
      <Text id={`swap.banner.${variant}`} values={{ addLiquidity: AddLiquidityCta }} />
    </Container>
  );
};
