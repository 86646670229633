import cls from 'classnames';
import { ResponsiveLine, PointTooltipProps } from '@nivo/line';
import { format, getTime } from 'date-fns';
import { useIntl } from 'react-intl';
import { last } from 'lodash-es';
import { Text } from '../Text/Text';
import { getDateFormat, theme } from './chartConfig';
import { Container } from '../Container/Container';
import { Timeframe } from './timeframe/Timeframe';
import { EmptyChartHistory } from './empty/EmptyChartHistory';
import { Spinner } from '../Spinner/Spinner';
import { ClassName } from 'core';

interface LineChartProps extends ClassName {
  data: Array<{ x: Date; y: string }> | undefined | null;
  timeframe: Timeframe;
  id: string;
  withYAxis?: boolean;
  yAxisTitleId?: string;
  xAxisTitleId?: string;
  isEmpty?: boolean;
  formatYAxis: (value: string) => string;
  formatTooltipYValue: (value: string) => string;
}

export const LineChart = ({
  data,
  timeframe,
  id,
  withYAxis,
  yAxisTitleId,
  className,
  isEmpty,
  formatYAxis,
  formatTooltipYValue,
}: LineChartProps) => {
  const intl = useIntl();

  const formatDate = (value: number) => format(value, getDateFormat(timeframe));

  const renderTooltip =
    (timeframe: Timeframe) =>
    ({ point }: PointTooltipProps) => {
      return (
        <Container className="bg-soft p-4 rounded" alignItems="center">
          <Text color="secondary" className="mr-3">
            {format(new Date(point.data.x), getDateFormat(timeframe))}
          </Text>
          <Text>{formatTooltipYValue(point.data.y.toString())}</Text>
        </Container>
      );
    };

  const renderContent = () => {
    switch (true) {
      case !!data && (data?.length || 0) > 2: {
        if (!data) {
          return null;
        }

        const lastYTick = Math.max(...data.map((item) => parseFloat(item.y))) * 1.3;
        const yTickStep = lastYTick / 4;
        const middleYTicks = Array.from({ length: 3 }, (_, i) => 0 + (i + 1) * yTickStep);
        const yTicks = [0, ...middleYTicks, lastYTick];

        const firstXtick = data[0].x.getTime();
        const lastXtick = getTime(last(data.map((item) => item.x)) || new Date());
        const xTickStep = (lastXtick - firstXtick) / 4;
        const middleXTicks = Array.from({ length: 3 }, (_, i) =>
          Math.floor(firstXtick + (i + 1) * xTickStep),
        );
        const xTicks = [firstXtick, ...middleXTicks, lastXtick];

        const element = document.getElementsByClassName('prose')[0];

        if (!element) {
          return null;
        }

        return (
          <>
            <Container className={cls('absolute inset-0 left-[-60px] right-[-60px]', className)}>
              <ResponsiveLine
                theme={theme}
                colors={[getComputedStyle(element).getPropertyValue('--icon-accent')]}
                data={[{ id, data }]}
                margin={{ top: 50, right: 68, bottom: 48, left: withYAxis ? 122 : 68 }}
                areaOpacity={0.6}
                curve="cardinal"
                xScale={{ type: 'time' }}
                axisBottom={{
                  tickSize: 10,
                  tickPadding: 10,
                  tickValues: data.length > 7 ? xTicks : 4,
                  format: formatDate,
                }}
                gridYValues={yTicks}
                axisLeft={
                  withYAxis
                    ? {
                        tickSize: 0,
                        tickValues: yTicks,
                        legend: yAxisTitleId && intl.formatMessage({ id: yAxisTitleId }),
                        legendPosition: 'middle',
                        legendOffset: -49,
                        format: formatYAxis,
                      }
                    : null
                }
                tooltip={renderTooltip(timeframe)}
                isInteractive
                useMesh
                enableCrosshair
                enableGridY={false}
                enableArea
                enablePoints={false}
                enableGridX={false}
                defs={[
                  {
                    id: 'gradientC',
                    type: 'linearGradient',
                    colors: [
                      { offset: 0, color: 'var(--icon-accent)', opacity: '0.4' },
                      { offset: 55, color: 'var(--icon-accent)', opacity: '0.1' },
                      { offset: 70, color: 'var(--icon-accent)', opacity: '0' },
                    ],
                  },
                ]}
                fill={[{ match: '*', id: 'gradientC' }]}
              />
            </Container>
            <div
              className={cls(
                `pointer-events-none absolute inset-y-0 ${
                  withYAxis ? 'left-[58px]' : 'left-[3px]'
                } bottom-[32px] w-[100px]`,

                'bg-gradient-to-r from-default from-0% to-transparent to-100%',
              )}
            />
            <div
              className={cls(
                'pointer-events-none absolute inset-y-0 right-[3px] bottom-[32px] w-[100px]',
                'bg-gradient-to-r from-transparent from-0% to-default to-100%',
              )}
            />
          </>
        );
      }
      case isEmpty:
        return <EmptyChartHistory />;

      default:
        return <Spinner />;
    }
  };

  return (
    <Container className="relative w-full h-full" alignItems="center" justifyContent="center">
      {renderContent()}
    </Container>
  );
};
