import { useQuery } from '@tanstack/react-query';
import { EnvConfig } from '../../../../../envConfig';
import { useApi, DEFAULT_QUERY_OPTIONS } from '../../../../../services';
import { getL2ToL1AssetMap } from '../services/rollupTokenAddressesService';
import { transformRollupTokenAddresMaps } from '../transformer/rollupTokenAddressesTransformer';

export const useRollupTokenAddresMapsQuery = () => {
  const api = useApi();

  const rollupTokenAddressMapsQuery = useQuery({
    queryKey: ['rollup-token-address-maps'],
    queryFn: getL2ToL1AssetMap(api),
    select: transformRollupTokenAddresMaps,
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!api && EnvConfig.isRollupEnv,
  });

  return { rollupTokenAddressMapsQuery };
};
