import { useCallback } from 'react';
import { getAdaptiveTxFee } from './AdaptiveTxFee';
import { useAssets } from '../../token';
import { useInternalBalance } from '../../balance';
import { EnvConfig } from '../../../envConfig';

// TODO: Remove condition when forked
const ADAPTIVE_FEE_ASSETS = EnvConfig.isKusamaEnv
  ? [
      ['0', '1'],
      ['4', '1000000000'],
      ['7', '1000000000'],
    ]
  : [
      ['0', '1'],
      ['1', '1000000000'],
    ];

export const useAdaptiveFee = () => {
  const { assetsMap } = useAssets();
  const { getFreeBalance } = useInternalBalance();

  const feeAssets = ADAPTIVE_FEE_ASSETS.map(([id, scaleFactor]) => {
    const asset = assetsMap.get(id);

    return asset ? { ...asset, scaleFactor } : null;
  }).filter(Boolean);

  const getAdaptiveFee = useCallback(
    (amount: string | null) =>
      amount ? getAdaptiveTxFee(amount, feeAssets, getFreeBalance) : null,
    [feeAssets, getFreeBalance],
  );

  return {
    getAdaptiveFee,
    isAdaptiveFeeReady: feeAssets.length > 0,
  };
};
