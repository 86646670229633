import { EnvConfig } from '../../../envConfig';
import { useAssets } from './useAssets';

// TODO: rename to useNativeAsset
export const useMangataAsset = () => {
  const { assetsMap } = useAssets();
  const mangataAsset = assetsMap.get(EnvConfig.TOKEN_ID) || null;

  return {
    mangataAsset,
  };
};
