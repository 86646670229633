import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum WalletStatus {
  NotConnected = 'notConnected',
  SelectWallet = 'selectWallet',
  SelectAccount = 'selectAccount',
  Connected = 'connected',
}

export interface WalletUIStore {
  activeTab: number;
  status: WalletStatus;
  setStatus: (status: WalletStatus) => void;
  setActiveTab: (tab: number) => void;
  toggleSelectWallet: () => void;
}

export const useWalletUIStore = create(
  devtools<WalletUIStore>((set) => ({
    activeTab: 0,
    status: WalletStatus.NotConnected,
    setStatus: (status) => set({ status }),
    setActiveTab: (activeTab) => set({ activeTab }),
    toggleSelectWallet: () =>
      set(({ status }) => {
        const isSelectWalletVisible = status === WalletStatus.SelectWallet;

        return {
          status: isSelectWalletVisible ? WalletStatus.NotConnected : WalletStatus.SelectWallet,
        };
      }),
  })),
);
