import numeral from 'numeral';
import { Decimal } from 'decimal.js';
import { ClassName, TestId } from 'core';
import { useMemo } from 'react';
import { isNil } from 'lodash-es';

export interface FormatAmountOptions {
  precision?: number;
  nonZeroPrecision?: boolean;
  maxChars?: number;
  minThreshold?: string;
}

export interface FormatAmountProps extends TestId {
  value: string | null | undefined;
  options?: FormatAmountOptions;
}

type FormatAmountComponentProps = FormatAmountProps & ClassName;

export function formatAmount(
  _value: string | null | undefined,
  options?: FormatAmountOptions,
): string | null {
  if (isNil(_value)) {
    return null;
  }

  const value = new Decimal(_value);

  if (value.isZero()) {
    return '0';
  }

  if (!options) {
    return formatWithCommas(_value);
  }

  const { minThreshold, precision, nonZeroPrecision, maxChars } = options;

  if (minThreshold && value.abs().lt(minThreshold)) {
    return `< ${minThreshold}`;
  }

  const amount = [
    ($: string) => (nonZeroPrecision ? handleNonZeroPrecision($, precision) : $),
    ($: string) => (!nonZeroPrecision && !isNil(precision) ? formatWithPrecision($, precision) : $),
    ($: string) =>
      !isNil(maxChars) ? formatWithMaxChars($, maxChars, precision, nonZeroPrecision) : $,
  ].reduce(($, fn) => {
    return fn($);
  }, value.toFixed());

  return formatWithCommas(amount);
}

function trimTrailingZeros(value: string): string {
  const [integer, decimal] = value.split('.');
  if (!decimal) return integer;
  const trimmedDecimal = decimal.replace(/0+$/, '');
  return trimmedDecimal ? `${integer}.${trimmedDecimal}` : integer;
}

function stripPrefix(value: string): { prefix: string; value: string } {
  if (value.startsWith('<') || value.startsWith('>')) {
    return { prefix: value.charAt(0), value: value.slice(1).trim() };
  }
  return { prefix: '', value };
}

function applyPrefix(prefix: string, value: string): string {
  return prefix ? `${prefix} ${value}`.trim() : value;
}

function formatWithCommas(_value: string): string {
  const { prefix, value } = stripPrefix(_value);

  const [integer, decimal] = value.split('.');
  const formattedInteger = numeral(integer).format('0,0');
  const amount = decimal ? `${formattedInteger}.${decimal}` : formattedInteger;

  return trimTrailingZeros(applyPrefix(prefix, amount));
}

function formatWithPrecision(_value: string, precision: number | undefined) {
  const value = new Decimal(_value);
  const [, decimals] = _value.split('.');

  if (!decimals) {
    return _value;
  }

  const precise = value.toFixed(precision ?? 0);

  return precise;
}

function roundOnFirstNonZeroDigit(_value: string, _precision: number | undefined): string {
  const value = new Decimal(_value);
  const [, decimals] = _value.split('.');

  if (!decimals) {
    return _value;
  }

  const firstNonZeroIndex = decimals.search(/[1-9]/);

  if (firstNonZeroIndex === -1) {
    return _value;
  }

  const precision = (_precision ?? 0) + firstNonZeroIndex + 1;

  const roundedAmount = value.toFixed(precision, Decimal.ROUND_HALF_UP);

  return roundedAmount;
}

function formatWithMaxChars(
  _value: string,
  maxChars: number,
  precision: number | undefined,
  nonZeroPrecision: boolean | undefined,
) {
  const roundedValue = roundOnFirstNonZeroDigit(_value, nonZeroPrecision ? precision : undefined);
  const [integer] = roundedValue.split('.');

  const commasCount =
    numeral('9'.repeat(maxChars - 1))
      .format('0,0')
      .split(',').length - 1;

  if (integer.length + commasCount > maxChars) {
    return `> ${'9'.repeat(maxChars - commasCount)}`;
  }

  const slicedAmount = roundedValue.slice(0, maxChars);

  if (slicedAmount.search(/[1-9]/) !== -1) {
    return trimTrailingZeros(slicedAmount);
  }

  const zerosCount = Math.max(maxChars - 3, 0);
  const amount = zerosCount === 0 ? '1' : `0.${'0'.repeat(zerosCount)}1`;
  return `< ${amount}`;
}

function handleNonZeroPrecision(_value: string, precision: number | undefined) {
  const value = new Decimal(_value);
  const [, fraction] = value.toFixed().split('.');

  if (fraction) {
    const firstNonZeroDigitPosition = fraction.search(/[1-9]/);
    const nonZeroPrecisionValue = firstNonZeroDigitPosition + (precision ?? 0);

    return value.toFixed(nonZeroPrecisionValue);
  }
  return value.toFixed();
}

export function FormatAmount({
  value,
  options,
  className,
  'data-testid': testId,
}: FormatAmountComponentProps) {
  const formattedValue = useMemo(() => formatAmount(value, options), [value, options]);
  return (
    <span className={className} data-testid={testId}>
      {formattedValue}
    </span>
  );
}
