import { useMemo } from 'react';
import { Web3 } from 'web3';
import { Children, Web3Context, Web3Contexts, useRollupChainsQuery } from 'core';

export const Web3Provider = ({ children }: Children) => {
  const { rollupChainsQuery } = useRollupChainsQuery();

  const chains = rollupChainsQuery.data;

  const web3 = useMemo(() => {
    if (!chains) {
      return null;
    }

    return chains.reduce((acc: Web3Contexts, chain) => {
      acc[chain.chainId] = new Web3(chain.rpcUrl);
      return acc;
    }, {});
  }, [chains]);

  return <Web3Context.Provider value={web3}>{children}</Web3Context.Provider>;
};
