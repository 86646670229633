import { TestId, TokenFilter, useFilteredAssets } from 'core';
import { AlertMessage, Container, IconButton, SettingsRow, Text } from '../../';
import { ReactComponent as BackIcon } from '../../../../assets/icons/arrow-left.svg';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash-es';

export interface TokenSelectorProps extends TestId {
  onBack(): void;
}
interface SettingsItem {
  key: TokenFilter;
  title: string;
}

const settingsItems: SettingsItem[] = [
  {
    key: TokenFilter.MangataDefault,
    title: 'token.settings.show.mangataDefaultTokens',
  },
  {
    key: TokenFilter.UserOwned,
    title: 'token.settings.show.userOwnedTokens',
  },
  {
    key: TokenFilter.LiquidityPool,
    title: 'token.settings.show.liquidityPoolTokens',
  },
  {
    key: TokenFilter.Other,
    title: 'token.settings.show.otherTokens',
  },
];

export const TokenSettings = ({
  'data-testid': testId = 'tokenSettings',
  onBack,
}: TokenSelectorProps) => {
  const intl = useIntl();
  const { tokenFilters, setTokensFilter, tokensByFilter } = useFilteredAssets();

  const handleSettingChange = (key?: TokenFilter) => (value: boolean) =>
    setTokensFilter(key, value);

  const showAllTokens = isEqual(settingsItems.map(({ key }) => key).sort(), tokenFilters?.sort());
  const allTokensCount = Object.values(tokensByFilter).reduce(
    (acc, tokens) => acc + (tokens?.length || 0),
    0,
  );

  return (
    <Container data-testid={testId} className="w-full pt-1 pb-2" column alignItems="stretch">
      <Container className="w-full px-3 mb-6" alignItems="center">
        <IconButton
          className="mr-3"
          Icon={BackIcon}
          onClick={onBack}
          size="s"
          data-testid={`${testId}-back`}
        />
        <Text type="title-1" id="token.settings.modal.title" />
      </Container>
      <SettingsRow
        title={intl.formatMessage({ id: 'token.settings.show.allAvailableTokens' })}
        description={intl.formatMessage(
          { id: 'token.settings.show.tokensCount' },
          { count: allTokensCount },
        )}
        value={showAllTokens}
        onChange={handleSettingChange()}
        data-testid={`${testId}-item`}
        className="py-3 mx-5"
      />
      <div className="border-b-1 border-default my-3 w-full h-[1px] scale-x-[1.067]" />
      {settingsItems.map(({ key, title }) => (
        <SettingsRow
          key={key}
          title={intl.formatMessage({ id: title })}
          description={intl.formatMessage(
            { id: 'token.settings.show.tokensCount' },
            { count: tokensByFilter[key]?.length || 0 },
          )}
          isDisabled={key === TokenFilter.MangataDefault}
          value={showAllTokens || !!tokenFilters?.find((filter) => filter === key)}
          onChange={handleSettingChange(key)}
          data-testid={`${testId}-item`}
          className="py-3 mx-5"
        />
      ))}
      <AlertMessage
        className="mx-5 mt-13"
        titleId="swap.token.warning.title"
        descriptionId="swap.token.warning.description"
      />
    </Container>
  );
};
