import { useMutation } from '@tanstack/react-query';
import {
  FaucetCaptchaVerificationProps,
  verifyFaucetCaptcha,
} from '../services/faucetCaptchaService';
import { AxiosError } from 'axios';

export const useFaucetCatpchaMutation = () => {
  const captchaMutation = useMutation<null, AxiosError, FaucetCaptchaVerificationProps>(
    verifyFaucetCaptcha,
  );

  return { captchaMutation };
};
