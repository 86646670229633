import { ReactComponent as TimeImage } from '../../../../assets/images/time.svg';
import { Container } from '../../Container/Container';
import { Text } from '../../Text/Text';

export const EmptyChartHistory = () => {
  return (
    <Container column alignItems="center" justifyContent="center">
      <TimeImage className="w-[32px] h-auto fill-secondary mt-[68px]" />
      <Text
        type="title-4"
        id="pool.detail.history.empty"
        color="secondary"
        className="text-center whitespace-pre-line mt-5"
      />
    </Container>
  );
};
