import { useQueries, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../services';
import {
  fetchCoingeckoContractInfo,
  CoingeckoContractInfoQueryKey,
} from '../services/contractInfoService';
import { CoingeckoContractInfo } from '../Coingecko';
import { RollupTokenAddressMaps } from '../../rollup/token/address/transformer/rollupTokenAddressesTransformer';

type CoingeckoContractInfoQueryOptions = UseQueryOptions<
  CoingeckoContractInfo | null,
  unknown,
  CoingeckoContractInfo | null,
  CoingeckoContractInfoQueryKey
>;

export type CoingeckoContractInfoQueries = Map<
  string,
  UseQueryResult<CoingeckoContractInfo | null, unknown>
>;

export const useCoingeckoContractInfoQueries = (
  addressMap: QueryOptional<RollupTokenAddressMaps | null>,
) => {
  const addresses = Array.from(addressMap?.l1ToL2?.entries() || []).flatMap(
    ([chainKey, l1AddressesMap]) =>
      Array.from(l1AddressesMap.keys()).map((address) => [address, chainKey]),
  );

  const queries: CoingeckoContractInfoQueryOptions[] = addresses
    ? addresses.map(([address, chainKey]) => ({
        ...DEFAULT_QUERY_OPTIONS,
        queryKey: ['coingecko-contract-info', address, chainKey],
        queryFn: fetchCoingeckoContractInfo,
        enabled: !!address && !!chainKey,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retryOnMount: false,
        retry: false,
      }))
    : [];

  const contractInfoQueries = useQueries({ queries });
  const areAllFetched = contractInfoQueries.every((query) => query.isFetched);

  if (!addresses) {
    return {
      contractInfoQueries: new Map(),
    };
  }

  return {
    areAllFetched,
    contractInfoQueries: new Map(contractInfoQueries.map((query, i) => [addresses[i][0], query])),
  };
};

export const useCoingeckoContractInfoQuery = (
  chainKey: QueryOptional<string>,
  address: QueryOptional<string>,
) => {
  const contractInfoQuery = useQuery(
    ['coingecko-contract-info', address, chainKey],
    fetchCoingeckoContractInfo,
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!address && !!chainKey,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      retry: false,
    },
  );

  return {
    contractInfoQuery,
  };
};
