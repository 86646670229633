import axios from 'axios';
import { EnvConfig } from '../../../../envConfig';

export interface FaucetCaptchaVerificationProps {
  address: string;
  token: string;
}

export const verifyFaucetCaptcha = async (props: FaucetCaptchaVerificationProps) => {
  const { address, token } = props;

  const { data } = await axios.get(
    `${EnvConfig.STASH_URL}/faucet/requestTokens/${address}/captcha/${token}`,
  );

  return data;
};
